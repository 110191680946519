import {
  AccordionAreaDeprecated,
  AccordionButtonDeprecated,
  Anchor,
  Text,
} from '@codecademy/gamut';
import styled from '@emotion/styled';
import { getJobPath } from '@mono/util-url';
import React, { useState } from 'react';

import { DepartmentJobsProps } from './types';

const AccordionTitle = styled(AccordionButtonDeprecated)`
  text-align: left;
  font-weight: normal;
  padding: 0;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin: 0.5rem 0;
  padding: 0;
`;

export const DepartmentJobs: React.FC<DepartmentJobsProps> = ({
  dept,
  jobs,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <AccordionAreaDeprecated
      expanded={expanded}
      top={
        <AccordionTitle
          expanded={expanded}
          onClick={() => setExpanded((state) => !state)}
        >
          <Text as="h3" fontSize={{ _: 22, lg: 26 }} whiteSpace="normal">
            {dept}
          </Text>
          {`${jobs.length} open position${jobs.length !== 1 ? 's' : ''}`}
        </AccordionTitle>
      }
    >
      <List>
        {jobs.map((job) => (
          <ListItem key={job.id}>
            <Anchor variant="standard" href={getJobPath(job.id)}>
              {job.title}
            </Anchor>
          </ListItem>
        ))}
      </List>
    </AccordionAreaDeprecated>
  );
};
